import { FormControl, IconButton, InputLabel, ListSubheader, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useApplicationContext } from '../../ApplicationContext';
import React, { useCallback, useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import './CompanyDropdown.css';
import { Company } from '../../types/Company';

const CompanyDropdown = () => {
    const { companies, selectCompany, currentUser } = useApplicationContext();
    let selectedCompanyId = sessionStorage.getItem('selectedCompanyId') || undefined;
    let isOwner = sessionStorage.getItem('isOwner') !== undefined ? sessionStorage.getItem('isOwner') === 'true' : undefined;
    const [defaultCompany, setDefaultCompany] = React.useState<Company>();

    useEffect(() => {
        if (currentUser?.defaultCompanyId) {
            let company = companies.find((c) => c.id === currentUser.defaultCompanyId && c.isOwner === currentUser.defaultCompanyIsOwner);
            setDefaultCompany(company);
        }
    }, [currentUser?.defaultCompanyId, currentUser?.defaultCompanyIsOwner, companies, setDefaultCompany]);

    const handleCompanySelect = useCallback(
        (event: SelectChangeEvent) => {
            if (event.target.value) {
                let [companyId, owns] = event.target.value.split('_');
                selectCompany(Number(companyId), owns === 'owner');
            }
        },
        [selectCompany],
    );

    const handleSetDefaultCompany = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, company) => {
            e.stopPropagation();

            axios
                .post(`/User/SetDefaultCompany`, {
                    id: company.id,
                    isOwner: company.isOwner,
                })
                .then(() => {
                    setDefaultCompany(company);
                });
        },
        [setDefaultCompany],
    );

    const companyMenuItem = useCallback(
        (company) => {
            // @ts-ignore
            let isDefault = defaultCompany?.id === company.id && defaultCompany?.isOwner === company.isOwner;
            return (
                <MenuItem
                    key={company.id}
                    value={`${company.id}_${company.isOwner ? 'owner' : 'operator'}`}
                    className={'company-selector__menu-item'}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Typography>{company.name}</Typography>
                    <IconButton
                        className={`company-selector__menu-item-icon ${isDefault ? 'company-selector__menu-item-icon--is-default' : ''}`}
                        size={'small'}
                        disabled={isDefault}
                        onClick={(e) => handleSetDefaultCompany(e, company)}
                    >
                        <HomeIcon />
                    </IconButton>
                </MenuItem>
            );
        },
        [defaultCompany, handleSetDefaultCompany],
    );

    return (
        <>
            {companies.length <= 1 ? (
                <div className={'top-bar__company-selector-label'}>
                    <span>{companies[0]?.name}</span>
                </div>
            ) : (
                <FormControl className={'top-bar__company-selector'} margin="dense" size="small">
                    <InputLabel id="company-select-label">Company</InputLabel>
                    <Select
                        id="company-select"
                        labelId="company-select-label"
                        label="Company"
                        onChange={handleCompanySelect}
                        value={`${selectedCompanyId}_${isOwner ? 'owner' : 'operator'}`}
                        renderValue={(value) => {
                            let [companyId] = value.split('_');
                            let company = companies.find((c) => c.id === Number(companyId));
                            return company?.name;
                        }}
                    >
                        <ListSubheader>Owner</ListSubheader>
                        {companies.filter((company) => company.isOwner).map(companyMenuItem)}
                        <ListSubheader>Operator</ListSubheader>
                        {companies.filter((company) => !company.isOwner).map(companyMenuItem)}
                    </Select>
                </FormControl>
            )}
        </>
    );
};

export default CompanyDropdown;
