import { useMemo, useState } from 'react';
import { Checkbox, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

type MultiselectListBoxOption = {
    label: string;
    value: string;
};
type MultiselectListBoxProps = {
    options: MultiselectListBoxOption[];
    selectedOptions: any[];
    setSelectedOptions: (selectedOptions: any[]) => void;
    listContainerProps?: any;
    containerStyle?: any;
};
const MultiselectListBox = ({ options, selectedOptions, setSelectedOptions, listContainerProps, containerStyle }: MultiselectListBoxProps) => {
    const [searchText, setSearchText] = useState('');
    const [allChecked, setAllChecked] = useState(selectedOptions.length === options.length);

    const onAllCheckboxClick = (e) => {
        if (e.target.checked) {
            const optionValues = options.map((x) => x.value);
            setSelectedOptions([...new Set([...selectedOptions, ...optionValues])]);
        } else {
            const optionValues = options.map((x) => x.value);
            setSelectedOptions([...new Set(selectedOptions.filter((x) => !optionValues.includes(x)))]);
        }
        setAllChecked(e.target.checked);
    };

    const handleToggle = (value: string) => () => {
        const currentIndex = selectedOptions.indexOf(value);
        const newChecked = [...selectedOptions];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
            allChecked && setAllChecked(false);
        }

        setSelectedOptions(newChecked);
    };

    const filteredOptions = useMemo(() => {
        return options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));
    }, [options, searchText]);

    return (
        <Grid
            sx={{
                border: '1px solid #ced4da',
                borderRadius: '6px',
                ...containerStyle,
            }}
        >
            <Grid container alignItems={'center'} sx={{ padding: '0.75rem 1rem', borderBottom: '1px solid #ced4da' }}>
                <Grid>
                    <Checkbox edge="start" onChange={onAllCheckboxClick} checked={allChecked} />
                </Grid>
                <Grid flexGrow={1}>
                    <TextField
                        sx={{ width: '100%' }}
                        variant={'standard'}
                        size={'small'}
                        placeholder={'Search'}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid>
                <List {...listContainerProps}>
                    {filteredOptions.map((option) => (
                        <ListItem key={option.value} disablePadding>
                            <ListItemButton onClick={handleToggle(option.value)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedOptions.includes(option.value)}
                                        inputProps={{ 'aria-labelledby': option.label }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={option.label} primary={`${option.label}`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

export default MultiselectListBox;
