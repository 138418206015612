import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { User } from './types/user';
import { ApplicationContextType } from './types/applicationContext';
import { Company } from './types/Company';

const ApplicationContext = React.createContext<ApplicationContextType>(null);

const useApplicationContext = () => {
    const context = React.useContext(ApplicationContext);

    if (context === undefined) {
        throw new Error('useApplicationContext must be used within an ApplicationContext.Provider');
    }
    return context;
};

const Provider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [navIsVisible, setNavIsVisible] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>(Number(sessionStorage.getItem('selectedCompanyId')) || undefined);
    const [isOwner, setIsOwner] = useState<boolean>(sessionStorage.getItem('isOwner') ? sessionStorage.getItem('isOwner') === 'true' : undefined);
    const [currentUser, setCurrentUser] = useState<User>();
    const [location, setLocation] = useState<any>();

    axios.defaults.baseURL = process.env.REACT_APP_SENIORLYTICS_API;

    axios.interceptors.request.use((config) => {
        let companyId = sessionStorage.getItem('selectedCompanyId') || undefined;
        config.headers['Selected-Company-Id'] = companyId?.toString();

        let isOwner = sessionStorage.getItem('isOwner') ? sessionStorage.getItem('isOwner') === 'true' : undefined;
        config.headers['Selected-Company-Is-Owner'] = isOwner?.toString();

        return config;
    });

    useEffect(() => {
        axios.interceptors.request.use(async (config) => {
            const token = await getAccessTokenSilently();
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    }, [getAccessTokenSilently]);

    const toggleNav = () => {
        setNavIsVisible(!navIsVisible);
    };

    const setTrackingDimension = (dimension: string, value: Number) => {
        (window as any).clarity && (window as any).clarity('set', dimension, value);
    };

    const selectCompany = useCallback((value: number, isOwner: boolean, fetchPermissions: boolean = true) => {
        sessionStorage.setItem('selectedCompanyId', value.toString());
        sessionStorage.setItem('isOwner', isOwner.toString());
        setSelectedCompanyId(value);
        setIsOwner(isOwner);
        setTrackingDimension('selectedCompanyId', value);
        if (fetchPermissions) {
            axios.get(`/User/GetPermissions`).then((response) => {
                setCurrentUser((u) => ({ ...u, allPermissions: response.data }));
            });
        }
    }, []);

    useEffect(() => {
        const _setLocation = (position: any) => {
            if (!position.coords) return;
            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        };
        navigator.geolocation.getCurrentPosition(_setLocation, _setLocation);
    }, []);

    useEffect(() => {
        axios.get(`/Company/PostLogin`).then((response) => {
            let user: User = response.data.user;
            let companyList = response.data.companies.map((c) => {
                return { id: c.id, name: c.name, isOwner: c.isOwner };
            });
            setCompanies(companyList);
            setTrackingDimension('userId', user.id);
            let companyId = sessionStorage.getItem('selectedCompanyId') || undefined;
            let isOwner = sessionStorage.getItem('isOwner') ? sessionStorage.getItem('isOwner') === 'true' : undefined;
            if (!companyId && isOwner === undefined) {
                let company;
                if (user.defaultCompanyId) {
                    company = companyList.find((c) => c.id === user.defaultCompanyId && c.isOwner === user.defaultCompanyIsOwner);
                }

                if (!company && companyList.length > 0) {
                    company = companyList[0];
                }

                if (company) {
                    selectCompany(company.id, company.isOwner, false);
                }
            }
            axios.get(`/User/GetPermissions`).then((response) => {
                user.allPermissions = response.data;
                setCurrentUser(user);
            });
        });

        axios.post(`/Users/RefreshCurrentUserLastActiveAt`).catch(console.error);
    }, [selectCompany]);

    const hasPermission = useCallback(
        (permission: string | string[]) => {
            if (!permission) return true;

            let perms = permission instanceof Array ? permission : [permission];
            return currentUser?.allPermissions?.some((p) => perms.includes(p.permissionName));
        },
        [currentUser],
    );

    return (
        <ApplicationContext.Provider
            value={{
                navIsVisible,
                toggleNav,
                companies,
                selectedCompanyId,
                isOwner,
                selectCompany,
                currentUser,
                location,
                hasPermission,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

const ApplicationProvider = Provider;

export { ApplicationContext, ApplicationProvider, useApplicationContext };
