import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Backdrop, Button, CircularProgress, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import UserCommunityAccessTab from './UserCommunityAccessTab';
import UserReportsAccessTab from './UserReportsAccessTab';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApplicationContext } from '../../../ApplicationContext';
import { useConfirm } from 'material-ui-confirm';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { enqueueSnackbar } from 'notistack';

const User = () => {
    const { selectedCompanyId, isOwner } = useApplicationContext();
    const { userId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const confirm = useConfirm();

    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [user, setUser] = useState(undefined);

    const setSelectedCommunityIds = useCallback(
        (communityIds: number[]) => {
            setUser({ ...user, communityIds: communityIds });
        },
        [user, setUser],
    );

    const setSelectedReportMetadataIds = useCallback(
        (reportMetadataIds: number[]) => {
            setUser({ ...user, reportMetadataIds: reportMetadataIds });
        },
        [user, setUser],
    );

    const setFirstName = useCallback(
        (firstName: string) => {
            setUser({ ...user, firstName });
        },
        [user, setUser],
    );

    const setLastName = useCallback(
        (lastName: string) => {
            setUser({ ...user, lastName });
        },
        [user, setUser],
    );

    const setTitle = useCallback(
        (title: string) => {
            setUser({ ...user, title });
        },
        [user, setUser],
    );

    const setEmailAddress = useCallback(
        (emailAddress: string) => {
            setUser({ ...user, emailAddress });
        },
        [user, setUser],
    );

    const TABS = useMemo(
        () => [
            {
                label: 'Community Access',
                component: <UserCommunityAccessTab selectedUserCommunityIds={user?.communityIds} setSelectedCommunityIds={setSelectedCommunityIds} />,
            },
            {
                label: 'Reports/Dashboards Access',
                component: (
                    <UserReportsAccessTab
                        selectedReportMetadataIds={user?.reportMetadataIds}
                        setSelectedReportMetadataIds={setSelectedReportMetadataIds}
                    />
                ),
            },
        ],
        [setSelectedCommunityIds, user, setSelectedReportMetadataIds],
    );

    const getUser = useCallback(
        (id: Number, isDuplicate: boolean = false) => {
            setIsLoading(true);
            const url = `/Users/${id}`;
            axios
                .get(url, {
                    params: { selectedCompanyId, isOwner },
                })
                .then((response) => {
                    if (isDuplicate) {
                        setUser({
                            communityIds: response.data.communityIds,
                            reportMetadataIds: response.data.reportMetadataIds,
                        });
                    } else {
                        setUser(response.data);
                    }
                })
                .finally(() => setIsLoading(false));
        },
        [selectedCompanyId, isOwner],
    );

    useEffect(() => {
        if (userId) {
            let id = Number(userId);
            if (Number.isInteger(id) && id > 0) {
                getUser(id);
            }
        } else if (location.state?.duplicateUserId) {
            getUser(location.state.duplicateUserId, true);
        }
    }, [userId, getUser, location.state]);

    const saveUser = useCallback(async () => {
        setIsSaving(true);
        const url = `/Users/${userId ? 'UpdateUser' : 'CreateUser'}`;
        user.selectedCompanyId = selectedCompanyId;
        user.isOwner = isOwner;
        axios
            .post(url, user)
            .then((response) => {
                navigate('/admin/users/users');
                enqueueSnackbar('User saved successfully!', { variant: 'success' });
            })
            .catch((error) => {
                enqueueSnackbar('Error saving user', { variant: 'error' });
            })
            .finally(() => setIsSaving(false));
    }, [selectedCompanyId, isOwner, user, userId, navigate]);

    const onCancel = useCallback(() => {
        navigate('/admin/users');
    }, [navigate]);

    const onArchive = useCallback(() => {
        confirm({
            description: 'Are you sure you want to archive this user?',
            confirmationText: 'Archive',
            confirmationButtonProps: { variant: 'contained', color: 'error' },
            dialogProps: { maxWidth: 'sm' },
        })
            .then(async () => {
                const url = `/Users/${userId}/Archive`;
                axios
                    .post(url, { companyId: selectedCompanyId, isOwner })
                    .then(() => {
                        if (userId) {
                            let id = Number(userId);
                            if (Number.isInteger(id) && id > 0) {
                                getUser(id);
                            }
                        }
                        enqueueSnackbar('User archived successfully!', { variant: 'success' });
                    })
                    .catch((error) => enqueueSnackbar('Error archiving user', { variant: 'error' }));
            })
            .catch(() => {});
    }, [confirm, selectedCompanyId, userId, isOwner, getUser]);

    const onUnArchive = useCallback(() => {
        confirm({
            description: 'Are you sure you want to un-archive this user?',
            confirmationText: 'Un-Archive',
            confirmationButtonProps: { variant: 'contained', color: 'error' },
            dialogProps: { maxWidth: 'sm' },
        })
            .then(async () => {
                const url = `/Users/${userId}/UnArchive`;
                axios
                    .post(url, { companyId: selectedCompanyId, isOwner })
                    .then(() => {
                        if (userId) {
                            let id = Number(userId);
                            if (Number.isInteger(id) && id > 0) {
                                getUser(id);
                            }
                        }
                        enqueueSnackbar('User unarchived successfully!', { variant: 'success' });
                    })
                    .catch((error) => enqueueSnackbar('Error unarchiving user', { variant: 'error' }));
            })
            .catch(() => {});
    }, [confirm, selectedCompanyId, userId, isOwner, getUser]);

    return (
        <>
            <Backdrop open={isLoading} sx={{ zIndex: 99 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                style={{
                    background: '#ffffff',
                    border: '1px solid #dee2e6',
                    borderRadius: 6,
                    padding: '1rem',
                }}
            >
                <Grid container direction={'column'}>
                    <Grid container justifyContent={'space-between'} margin={0.5}>
                        <Grid container spacing={1} alignContent={'center'}>
                            <Grid>
                                <CreateOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid>
                                <Typography style={{ fontWeight: 'bold' }}>Edit User Details</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignContent={'center'}>
                            <Grid>
                                {user != null && user.id > 0 && (
                                    <Button variant={'text'} color={'error'} onClick={user?.isActive ? onArchive : onUnArchive}>
                                        {user.isActive ? 'Archive' : 'Un-Archive'}
                                    </Button>
                                )}
                            </Grid>
                            <Grid>
                                <Button variant={'outlined'} onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid>
                                <LoadingButton
                                    size="small"
                                    onClick={saveUser}
                                    loading={isSaving}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <hr />
                    </Grid>
                    <Grid margin={1}>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid container spacing={2}>
                                <Grid flexGrow={1}>
                                    <TextField
                                        size={'small'}
                                        label={'First Name'}
                                        value={user?.firstName ?? ''}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid flexGrow={1}>
                                    <TextField
                                        size={'small'}
                                        label={'Last Name'}
                                        value={user?.lastName ?? ''}
                                        onChange={(e) => setLastName(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid flexGrow={1}>
                                    <TextField
                                        size={'small'}
                                        label={'Email'}
                                        disabled={user?.id > 0}
                                        value={user?.emailAddress ?? ''}
                                        onChange={(e) => setEmailAddress(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid flexGrow={1}>
                                    <TextField
                                        size={'small'}
                                        label={'Title'}
                                        value={user?.title ?? ''}
                                        onChange={(e) => setTitle(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'column'} marginTop={2}>
                        <Grid>
                            <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
                                {TABS.map((tab, index) => (
                                    <Tab key={index} label={tab.label} />
                                ))}
                            </Tabs>
                        </Grid>
                        <Grid>
                            {TABS.map((tab, index) => (
                                <Grid key={tab.label} role={'tabpanel'} hidden={activeTab !== index}>
                                    {tab.component}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default User;
