import Grid from '@mui/material/Unstable_Grid2';
import MultiselectListBox from '../../MultiselectListBox';
import { useApplicationContext } from '../../../ApplicationContext';
import { useEffect, useState } from 'react';
import axios from 'axios';

type UserReportsAccessTabProps = {
    selectedReportMetadataIds: any;
    setSelectedReportMetadataIds: (reports: number[]) => void;
};

const UserReportsAccessTab = ({ selectedReportMetadataIds, setSelectedReportMetadataIds }: UserReportsAccessTabProps) => {
    const { selectedCompanyId } = useApplicationContext();
    const [reports, setReports] = useState([]);

    useEffect(() => {
        const url = `/Company/ReportsAndDashboards`;
        axios
            .get(url, {
                params: { selectedCompanyId },
            })
            .then((response) => {
                setReports(response.data);
            });
    }, [selectedCompanyId]);

    return (
        <Grid container marginTop={2}>
            <Grid container direction={'column'} spacing={2} marginRight={6}>
                <Grid>Reports</Grid>
                <Grid>
                    <MultiselectListBox
                        options={reports
                            .filter((x) => !x.isDashboard)
                            .map((x) => {
                                return { label: x.reportName, value: x.id };
                            })}
                        selectedOptions={selectedReportMetadataIds ?? []}
                        setSelectedOptions={setSelectedReportMetadataIds}
                    />
                </Grid>
            </Grid>
            <Grid container direction={'column'} spacing={2}>
                <Grid>Dashboards</Grid>
                <Grid>
                    <MultiselectListBox
                        options={reports
                            .filter((x) => x.isDashboard)
                            .map((x) => {
                                return { label: x.reportName, value: x.id };
                            })}
                        selectedOptions={selectedReportMetadataIds ?? []}
                        setSelectedOptions={setSelectedReportMetadataIds}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserReportsAccessTab;
