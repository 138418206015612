import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CommunityInformation from './CommunityInformation';
import axios from 'axios';
import { useApplicationContext } from '../../../ApplicationContext';
import CommunityUnits from './CommunityUnits';

const CommunityPage = () => {
    const { communityId, tab } = useParams();
    const { selectedCompanyId, isOwner } = useApplicationContext();
    const [isLoading, setIsLoading] = useState(false);
    const [community, setCommunity] = useState({});
    const [activeTab, setActiveTab] = React.useState(0);

    const TABS = useMemo(
        () => [
            {
                path: 'information',
                label: 'Information',
                component: <CommunityInformation community={community} isLoading={isLoading} />,
            },
            {
                path: 'units',
                label: 'Units',
                component: <CommunityUnits community={community} isLoading={isLoading} />,
            },
        ],
        [community, isLoading],
    );

    React.useEffect(() => {
        const newTab = TABS.findIndex((t) => t.path === tab);
        setActiveTab(newTab >= 0 ? newTab : 0);
    }, [tab, TABS]);

    const getCommunity = useCallback(async () => {
        const url = `/Community/${selectedCompanyId}/Communities/${communityId}`;
        return axios
            .get(url, {
                params: { isOwner },
            })
            .then((response) => {
                setCommunity(response.data);
            });
    }, [selectedCompanyId, communityId, isOwner]);

    useEffect(() => {
        setIsLoading(true);
        getCommunity().finally(() => {
            setIsLoading(false);
        });
    }, [getCommunity]);

    return (
        <div
            style={{
                background: '#ffffff',
                border: '1px solid #dee2e6',
                borderRadius: 6,
                padding: '1rem',
            }}
        >
            <Grid container direction={'column'}>
                <Grid sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
                        {TABS.map((tab, index) => (
                            <Tab key={index} label={tab.label} />
                        ))}
                    </Tabs>
                </Grid>
                <Grid>{TABS[activeTab].component}</Grid>
            </Grid>
        </div>
    );
};

export default CommunityPage;
