import useCommunities from '../../../hooks/useCommunities';
import MultiselectListBox from '../../MultiselectListBox';
import Grid from '@mui/material/Unstable_Grid2';

type UserCommunityAccessTabProps = {
    selectedUserCommunityIds: any;
    setSelectedCommunityIds: (communityIds: number[]) => void;
};

const UserCommunityAccessTab = ({ selectedUserCommunityIds, setSelectedCommunityIds }: UserCommunityAccessTabProps) => {
    const { communities } = useCommunities();

    return (
        <Grid container marginTop={2}>
            <Grid container direction={'column'} spacing={2} marginRight={6}>
                <Grid>Communities</Grid>
                <Grid>
                    <MultiselectListBox
                        options={communities.map((x) => {
                            return { label: x.name, value: x.id };
                        })}
                        selectedOptions={selectedUserCommunityIds ?? []}
                        setSelectedOptions={setSelectedCommunityIds}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserCommunityAccessTab;
