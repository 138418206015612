import axios from 'axios';
import { useCallback } from 'react';
import { ReportComment } from '../contexts/ReportContext';
import { useApplicationContext } from '../ApplicationContext';

const REPORT_API_URL = `/Reports`;

const useReportService = () => {
    const { selectedCompanyId } = useApplicationContext();

    const getReportById = useCallback(
        async (reportId: string) => {
            const params = new URLSearchParams();
            params.append('companyId', selectedCompanyId?.toString());

            return axios.get(`${REPORT_API_URL}/${reportId}/ReportMetadata`, {
                headers: {
                    'Content-Type': 'text/json',
                },
                params,
            });
        },
        [selectedCompanyId],
    );

    const getReportComment = useCallback(async (reportId: string, date: string, communityId: string) => {
        const params = new URLSearchParams();
        params.append('reportId', reportId);
        params.append('date', date);
        params.append('communityId', communityId);

        return axios.get(`${REPORT_API_URL}/Comments`, {
            headers: {
                'Content-Type': 'text/json',
            },
            params,
        });
    }, []);

    const addReportComment = useCallback(async ({ comment = '', communityId, reportId, year, month }: ReportComment) => {
        const commentUrl = `${REPORT_API_URL}/Comments/AddComment`;
        const date = new Date(year, month, 1);

        return await axios.post(commentUrl, {
            comment,
            communityId,
            reportId,
            date,
        });
    }, []);

    const deleteReportComment = useCallback(async (commentId: any) => {
        const deleteUrl = `${REPORT_API_URL}/Comments/${commentId}`;

        return await axios.delete(deleteUrl);
    }, []);

    return { getReportById, getReportComment, addReportComment, deleteReportComment };
};

export default useReportService;
