import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, LinearProgress, TextField } from '@mui/material';
import SLTable from '../../Table/SLTable';
import SLTableToolbar from '../../Table/SLTableToolbar';
import { enqueueSnackbar } from 'notistack';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useApplicationContext } from '../../../ApplicationContext';

export default function CommunityUnits({ community, isLoading }) {
    const [units, setUnits] = useState(community.units ?? []);
    const [editedRows, setEditedRows] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const apiRef = useGridApiRef();
    const { selectedCompanyId } = useApplicationContext();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        axios.get(`/Community/UnitTypes`).then((response) => {
            setUnitTypes(response.data);
        });
    }, []);

    const processRowUpdate = useCallback(
        (newRow: any) => {
            const updatedRows = units.map((row) => (row.id === newRow.id ? newRow : row));
            setUnits(updatedRows);

            setEditedRows((prevEditedRows) => {
                const isAlreadyEdited = prevEditedRows.some((row) => row.id === newRow.id);
                return isAlreadyEdited ? prevEditedRows.map((row) => (row.id === newRow.id ? newRow : row)) : [...prevEditedRows, newRow];
            });

            return newRow;
        },
        [units],
    );

    const columns = useMemo(
        () => [
            { field: 'unitName', headerName: 'Unit' },
            {
                field: 'unitType.unitTypeName',
                valueGetter: (params) => params.row.unitType?.unitTypeName,
                headerName: 'Unit Type',
                width: 250,
                renderCell: (params) => {
                    return (
                        <Autocomplete
                            disablePortal
                            id="unitType"
                            size={'small'}
                            fullWidth
                            loading={unitTypes.length === 0}
                            getOptionLabel={(option) => option.unitTypeName}
                            options={unitTypes}
                            defaultValue={params.row.unitType || null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            renderInput={(params) => <TextField {...params} label="Unit Type" />}
                            onChange={(event, newValue) => {
                                const newRow = { ...params.row, unitType: newValue, unitTypeId: newValue.id };
                                processRowUpdate(newRow);
                                apiRef.current.updateRows([{ id: newRow.id, unitType: newValue, unitTypeId: newValue.id }]);
                            }}
                        />
                    );
                },
            },
            { field: 'beds', headerName: 'Beds', editable: true, type: 'number' },
            { field: 'capacity', headerName: 'Capacity', editable: true, type: 'number' },
            { field: 'sqFt', headerName: 'SqFt', editable: true, type: 'number' },
            { field: 'floorplan', headerName: 'Floor Plan', editable: true, width: 250 },
            { field: 'floorplanCategory', headerName: 'Floor Plan Category', editable: true, width: 250 },
            { field: 'slCategory', headerName: 'Category', editable: true, width: 250 },
        ],
        [unitTypes, apiRef, processRowUpdate],
    );

    const handleProcessRowUpdateError = (error) => {
        enqueueSnackbar('Error updating row: ' + error, { variant: 'error' });
    };

    const handleSave = async () => {
        const bedsIsDecimal = editedRows.some((row) => row.beds % 1 !== 0);
        const capacityIsDecimal = editedRows.some((row) => row.capacity % 1 !== 0);

        if (bedsIsDecimal || capacityIsDecimal) {
            enqueueSnackbar('Beds and Capacity must be whole numbers.', { variant: 'error' });
            return;
        }

        setIsSaving(true);
        const url = `/Community/${selectedCompanyId}/Communities/${community.id}/Units`;
        await axios
            .patch(url, editedRows)
            .catch((error) => {
                enqueueSnackbar('Error saving units: ' + error, { variant: 'error' });
            })
            .finally(() => setIsSaving(false));
    };

    return (
        <Box>
            <SLTable
                apiRef={apiRef}
                density={'comfortable'}
                sx={{
                    marginTop: '0',
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row.Mui-hovered': {
                        cursor: 'pointer',
                        height: 'auto',
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'name' }],
                    },
                }}
                rows={community.units ?? []}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                columns={columns}
                disableColumnFilter
                disableColumnMenu
                disableColumnPinning
                disableColumnSelector
                disableDensitySelector
                rowSelection={false}
                slots={{ toolbar: SLTableToolbar, loadingOverlay: LinearProgress }}
                slotProps={{
                    toolbar: {
                        saveButton: (
                            <Button variant={'contained'} onClick={handleSave} disabled={isSaving}>
                                {isSaving && <CircularProgress color={'inherit'} size={20} sx={{ mr: 1 }} />}
                                Save
                            </Button>
                        ),
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 1000 },
                    },
                    loadingOverlay: {
                        color: 'primary',
                        sx: {
                            height: '4px',
                        },
                    },
                }}
                loading={isLoading}
                autoHeight
                stackOnMobile
            />
        </Box>
    );
}
