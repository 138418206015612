import axios from 'axios';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../../ApplicationContext';

type CommunityNameProps = {
    communityId: string;
};

const CommunityName = ({ communityId }: CommunityNameProps) => {
    const { selectedCompanyId } = useApplicationContext();
    const [communityName, setCommunityName] = useState('');

    useEffect(() => {
        const url = `/Community/${selectedCompanyId}/Communities/${communityId}/Name/`;
        axios.get(url).then((response) => {
            setCommunityName(response.data);
        });
    }, [communityId, selectedCompanyId]);

    return <>{communityName}</>;
};

export default CommunityName;
