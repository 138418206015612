import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    Tooltip,
    FormControl,
    InputLabel,
    Stack,
    SelectChangeEvent,
    useTheme,
} from '@mui/material';
import { useReportContext } from '../../contexts/ReportContext';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import Modal from '../Modal/Modal';
import useCommunities from '../../hooks/useCommunities';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { MONTH_OPTIONS } from '../Budget/BudgetVersionEditor/Constants';
import { useConfirm } from 'material-ui-confirm';
import useReportService from '../../hooks/useReportService';
import { MOBILE_WIDTH } from '../../lib/constants';
import loadable from '@loadable/component';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = loadable(() => import('react-quill'));

const MODULES = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ],
};

const FORMATS = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'color', 'background'];
const IS_MOBILE = window.innerWidth < MOBILE_WIDTH;

const ReportCommentButton = ({ reportId }) => {
    const { getComment, addComment, reportRef } = useReportContext();
    const { getReportById } = useReportService();
    const { communities } = useCommunities(true);
    const confirm = useConfirm();
    const theme = useTheme();
    const editor = useRef(null);
    const [showButton, setShowButton] = useState(false);
    const [selectedCommunity, setSelectedCommunity] = useState<any>(null);
    const [year, setYear] = useState(dayjs());
    const [month, setMonth] = useState(dayjs().set('month', 11));
    const [comment, setComment] = useState('');
    const [commentId, setCommentId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchingComment, setFetchingComment] = useState(false);
    const reportRefreshThrottle = useRef(null);
    const isRefreshQueued = useRef(false);

    const refreshWithThrottle = useCallback(() => {
        if (!reportRefreshThrottle.current) {
            reportRefreshThrottle.current = true;
            reportRef?.current?.embed?.refresh().catch(console.error);
            setTimeout(() => {
                if (isRefreshQueued.current) {
                    isRefreshQueued.current = false;
                    reportRef?.current?.embed?.refresh().catch(console.error);

                    setTimeout(() => {
                        reportRefreshThrottle.current = null;
                    }, 15000);
                } else {
                    reportRefreshThrottle.current = null;
                }
            }, 15000);
        } else {
            isRefreshQueued.current = true;
        }
    }, [reportRef]);

    const _addComment = () => {
        const text = editor.current?.getEditor().getText();

        setIsLoading(true);
        if ((!text || !text.trim()) && commentId) {
            confirm({
                description: 'Are you sure you want to remove this comment?',
                title: 'Remove Comment',
            })
                .then(() => {
                    addComment({
                        comment: '',
                        communityId: selectedCommunity.id,
                        year: year.year(),
                        month: month.month(),
                        reportId,
                    })
                        .then(() => {
                            closeModal();
                            refreshWithThrottle();
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else if (text) {
            addComment({
                comment,
                communityId: selectedCommunity.id,
                year: year.year(),
                month: month.month(),
                reportId,
            })
                .then(() => {
                    closeModal();
                    refreshWithThrottle();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        reportId &&
            getReportById(reportId).then((r) => {
                setShowButton(r.data.allowComments);
            });
    }, [reportId, getReportById]);

    useEffect(() => {
        if (!selectedCommunity || !showModal) return;

        setFetchingComment(true);
        getComment(reportId.toUpperCase(), year.year(), month.month(), selectedCommunity?.id)
            .then((c) => {
                setComment(c.data?.comment || '');

                setCommentId(c.data?.id);
            })
            .catch((e) => console.log(e))
            .finally(() => setFetchingComment(false));
    }, [reportId, year, month, selectedCommunity, showModal, getComment]);

    const openModal = useCallback(async () => {
        if (reportId) {
            const filters = await reportRef?.embed.getFilters();

            if (!filters) return;

            const communityFilter = filters.find((f) => f.target.table === 'Facility' && f.target.column === 'Community');
            const yearFilter = filters.find((f) => f.target.table === 'Date' && f.target.hierarchyLevel === 'Year');
            const monthFilter = filters.find((f) => f.target.table === 'Date' && f.target.hierarchyLevel === 'Month');

            if (yearFilter?.values.length > 0 && ['In', 'NotIn'].includes(yearFilter.operator)) {
                if (yearFilter.operator === 'In') {
                    const lastYear = yearFilter.values[yearFilter.values.length - 1];
                    setYear(dayjs().set('year', lastYear));
                } else {
                    let year: number = dayjs().year();
                    yearFilter.values.reverse().some((y) => {
                        // eslint-disable-next-line eqeqeq -- string == int
                        if (y == year) {
                            year--;
                            return false;
                        }
                        return true;
                    });
                }
            } else {
                setYear(dayjs());
            }

            if (monthFilter?.values.length > 0 && ['In', 'NotIn'].includes(monthFilter.operator)) {
                if (monthFilter.operator === 'In') {
                    const lastMonth = monthFilter.values[monthFilter.values.length - 1];
                    setMonth(dayjs().set('month', [...MONTH_OPTIONS].reverse().find((m) => m.fullName === lastMonth).value));
                } else {
                    const months = [...MONTH_OPTIONS].reverse().filter((m) => !monthFilter.values.some((v: string) => v === m.fullName));
                    setMonth(dayjs().set('month', months[0].value));
                }
            } else {
                setMonth(dayjs().set('month', 11));
            }

            if (communityFilter.values.length > 0 && ['In', 'NotIn'].includes(communityFilter.operator)) {
                if (communityFilter.operator === 'In') {
                    setSelectedCommunity(communities.find((c) => c.name === communityFilter.values[0]));
                } else if (communityFilter.operator === 'NotIn') {
                    setSelectedCommunity(communities.filter((c) => !communityFilter.values.some((v) => v === c.name))[0]);
                }
            } else {
                setSelectedCommunity(communities[0]);
            }

            setShowModal(true);
        }
    }, [reportId, communities, reportRef]);

    const closeModal = useCallback(() => {
        setShowModal(false);
        setCommentId(null);
        setComment('');
        setIsLoading(false);
        setFetchingComment(false);
    }, []);

    const onCommunitySelect = (event: SelectChangeEvent<number[]>) => {
        const {
            target: { value },
        } = event;

        setSelectedCommunity(value);
    };

    if (!showButton) return null;

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
            <Tooltip title={'Add Comment'}>
                <IconButton
                    disabled={!reportId || communities.length === 0}
                    size={'medium'}
                    aria-label="comment"
                    color={'primary'}
                    onClick={openModal}
                >
                    <AddCommentOutlinedIcon fontSize={'medium'} />
                </IconButton>
            </Tooltip>
            <Modal
                open={showModal}
                style={{
                    [theme.breakpoints.down('sm')]: {
                        maxWidth: 100,
                    },
                    [theme.breakpoints.down('md')]: {
                        maxWidth: 400,
                    },
                }}
                title={'Add Comment'}
                onClose={closeModal}
            >
                <Stack spacing={2} sx={{ mb: 1 }}>
                    <Stack direction={'row'} spacing={2}>
                        <DatePicker
                            label={'Year'}
                            views={['year']}
                            defaultValue={year}
                            slotProps={{ textField: { size: 'small' } }}
                            value={year}
                            onChange={setYear}
                        />
                        <DatePicker
                            label={'Month'}
                            views={['month']}
                            slotProps={{ textField: { size: 'small' } }}
                            defaultValue={month}
                            onChange={setMonth}
                        />
                    </Stack>
                    <FormControl>
                        <InputLabel size={'small'} id="comment-community-checkbox-label">
                            Community
                        </InputLabel>
                        <Select
                            size={'small'}
                            labelId="comment-community-checkbox-label"
                            id="comment-community-checkbox"
                            value={selectedCommunity}
                            onChange={onCommunitySelect}
                            input={<OutlinedInput label="Community" />}
                            renderValue={(c) => c.name}
                        >
                            {communities.map((c) => (
                                <MenuItem key={c.name} value={c}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <ReactQuill
                        ref={editor}
                        style={{
                            height: 200,
                            marginBottom: IS_MOBILE ? 62 : 40,
                            [theme.breakpoints.down('sm')]: {
                                marginBottom: 62,
                            },
                        }}
                        formats={FORMATS}
                        modules={MODULES}
                        theme="snow"
                        value={comment}
                        onChange={setComment}
                    />
                </Stack>
                <div className={'flex flex-column modal__footer'}>
                    <Box sx={{ m: '0 0 1.5rem' }} className={'button-row flex flex-row justify-space-between'}>
                        <div className={'modal__footer__left'}></div>
                        <Stack direction={'row'} className={'modal__footer__right'} sx={{ mt: 1 }}>
                            <Button sx={{ marginRight: '1rem' }} variant="outlined" disabled={isLoading} className="cancel" onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button
                                disableElevation
                                variant="contained"
                                disabled={isLoading || !year || !month || !selectedCommunity || fetchingComment}
                                onClick={_addComment}
                            >
                                {isLoading && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            marginRight: '0.5rem',
                                        }}
                                    >
                                        <CircularProgress size="1rem" color="inherit" />
                                    </Box>
                                )}
                                Save
                            </Button>
                        </Stack>
                    </Box>
                </div>
            </Modal>
        </Box>
    );
};

export default ReportCommentButton;
